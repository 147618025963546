import $ from 'jquery';
require('jquery-ui');

$(() => {

    $('input.datePicker').datepicker({
        changeMonth: true,
        changeYear: true,
        showButtonPanel: false,
        dateFormat: 'dd-mm-yy',
    });

    const $dateTo = $('input.date_to');
    const $dateFrom = $('input.date_from');

    $dateTo.datepicker('option', 'maxDate', '0');

    $dateTo.datepicker('option', 'onSelect', (dateText) => {
        $dateFrom.datepicker('option', 'maxDate', dateText);
    });

    $dateFrom.datepicker('option', 'onSelect', (dateText) => {
        $dateTo.datepicker('option', 'minDate', dateText);
    });
})